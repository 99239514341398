import packageJson from '../../package.json';

export const environment = {
	production: false,
	apiUrl: 'https://api.fletespormexico.com.mx/api/',
	// apiUrl: 'https://localhost:8081/api/',
	// apiUrl: 'http://localhost:5221/api/',
	// apiUrl: 'https://localhost:44330/api/',
	socket: 'https://api.fletespormexico.com.mx/hub/',
	firebase: {
		apiKey: 'AIzaSyBgDZeER7nA3wDzoaRR6z_asLkgZQHOKH0',
		authDomain: 'tranquil-osprey-415718.firebaseapp.com',
		projectId: 'tranquil-osprey-415718',
		storageBucket: 'tranquil-osprey-415718.appspot.com',
		messagingSenderId: '442193564214',
		appId: '1:442193564214:web:f2a8ffcf6a94ce90833e97',
		measurementId: 'G-RNHBJ658LX',
	},
	version: packageJson.version,
};
