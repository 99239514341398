{
  "name": "freights-app",
  "version": "v1.0.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0 --port 4200 --disable-host-check",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sync:ios": "npx cap sync ios"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.3.8",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/google-maps": "^17.2.1",
    "@angular/material": "^17.3.8",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@capacitor-community/file-opener": "^1.0.5",
    "@capacitor-community/native-audio": "^5.0.1",
    "@capacitor/android": "5.7.0",
    "@capacitor/app": "5.0.7",
    "@capacitor/core": "5.7.0",
    "@capacitor/device": "^5.0.8",
    "@capacitor/filesystem": "^5.2.2",
    "@capacitor/geolocation": "^5.0.7",
    "@capacitor/haptics": "5.0.7",
    "@capacitor/ios": "5.7.0",
    "@capacitor/keyboard": "5.0.8",
    "@capacitor/preferences": "^5.0.8",
    "@capacitor/push-notifications": "^5.1.1",
    "@capacitor/share": "^5.0.8",
    "@capacitor/splash-screen": "^5.0.7",
    "@capacitor/status-bar": "^5.0.7",
    "@fooloomanzoo/datetime-input": "^3.0.4",
    "@googlemaps/markerclustererplus": "^1.2.10",
    "@ionic/angular": "^7.7.3",
    "@ionic/utils-process": "^2.1.12",
    "@microsoft/signalr": "^7.0.14",
    "@tailwindcss/aspect-ratio": "^0.4.2",
    "@tailwindcss/forms": "^0.5.7",
    "@tailwindcss/typography": "^0.5.10",
    "@teamhive/rich-text-editor": "^2.3.2",
    "@wfpena/angular-wysiwyg": "^1.1.3",
    "capacitor-file-picker": "^1.0.10",
    "chroma-js": "^2.4.2",
    "clear": "^0.1.0",
    "flowbite": "^2.2.1",
    "flowbite-datepicker": "^1.2.6",
    "howler": "^2.2.4",
    "ionicons": "^7.3.1",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ng2-pdf-viewer": "^10.3.4",
    "ngx-extended-pdf-viewer": "^20.5.0-alpha.2",
    "rxjs": "~7.8.0",
    "sweetalert2": "^11.10.6",
    "tailwind-children": "^0.5.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@capacitor/cli": "5.7.0",
    "@ionic/angular-toolkit": "latest",
    "@types/howler": "^2.2.11",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "3.1.1",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.3.2"
  }
}
